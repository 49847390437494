import React from "react";
import KPIChart from "./KPIChart";
import KPIHeader from "./KPIHeader";

const KPIs = props => {
    const [data, setData] = React.useState({});

    React.useEffect(() => {
        const fetchData = async () => {
            const data = await fetch(`data/${props.name}.json?t=${new Date().valueOf()}`);
            let json = await data.json();
            setData(json);
        }
        
        fetchData();
    }, data)

    return (
        <div className='OKRContainer'>
            <KPIHeader data={data} />
            <div className="flexbox">
                {data.OKR && data.OKR.map((kpi, i) => <KPIChart key={i} props={{...kpi, index: i, kpiCount: data.OKR.length }}/>)}
            </div>
        </div>
    )

}
export default KPIs;

import React from "react";

const KPIHeader = props => {
    return (
        <div className='KPIHeader'>
            <img alt="" src={props.data.logo_URL} />
            <strong>{props.data.title}</strong>
            <span>{props.data.time_period}</span>
        </div>
    )
}

export default KPIHeader;
import React from "react";

const Bar = data => {
    return (
        <div className="barContainer">
            <h4>Actual</h4>
            <ul className="barValues">
                <li>{data.props.base_value}</li>
                <li>{data.props.actual_value}</li>
                <li>{data.props.target_value}</li>
            </ul>

            <div className="barChart">
                <div className="baseValue" style={{
                    width: `${data.props.chart_actual}%`
                }}></div>
                <div className="actualValue" style={{
                    width: `${data.props.chart_forecast}%`
                }}></div>
                <div className="targetValue" style={{
                    width: `${100 - data.props.chart_actual - data.props.chart_forecast}%`
                }}></div>
            </div>
        </div>
    )
}

export default Bar;
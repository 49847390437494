import React from "react";
import GaugeChart from 'react-gauge-chart'

const Gauge = data => {
    const missing = (100 - data.props.forecast - data.props.actual) / 100; 
    const actual = (data.props.actual - missing) / 100;
    const forecast = (data.props.forecast - missing) / 100;

    setTimeout(() => {
        //fixes gauge chart issue
        window.dispatchEvent(new Event('resize'));
    })

    return (
        <div className="gaugeContainer">
            <GaugeChart id={`gauge-chart${data.props.index}`} 
                nrOfLevels={3} 
                percent={0.56} 
                needleColor="#345243"
                arcPadding={0}
                arcsLength={[actual, forecast, missing]}
                colors={['#17C37B', '#7ED321', '#EF303B']}
                cornerRadius={1} 
            />
        </div>
    )
}

export default Gauge;
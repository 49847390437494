import React from "react";
import Bar from "./charts/bar";
import Gauge from "./charts/gauge";

const KPIChart = data => {
    return (
        <div className='KPIContainer' style={{width: 100 / data.props.kpiCount + '%'}}>
            <div className="objective">
                <h3>{data.props.title}</h3>
                <h2>{data.props.percent}%</h2>
                <Gauge props={data.props} />
            </div>

            <ul>
                {data.props.KR.map((kpi, i) => 
                    <li key={i}>
                        <h1 className="kpiHead">
                            {kpi.title} <strong>({kpi.weight}%)</strong>
                        </h1>

                        <Bar props={kpi} />
                    </li>
                )}
            </ul>
        </div>
    )
}

export default KPIChart;